import "./bootstrap";
import "../css/app.css";
import { methods } from "./base.js";

import { createApp, type DefineComponent, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || "Elite Camps";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob<DefineComponent>("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        if (import.meta.env.VITE_APP_ENV === "production") {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: false,
                    }),
                ],
                tracesSampleRate: import.meta.env
                    .VITE_SENTRY_TRACES_SAMPLE_RATE,
                // Session Replay
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            });
        }
        app.use(plugin).use(ZiggyVue).mixin(methods).mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
